/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classnames from 'classnames';

import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';

import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';

import { Trans, useTranslation } from 'src/i18n';
import { TP, ENTITY_TYPE, ARTIST_TYPE_ID, PROFILE_TYPES } from 'constants/index';

import classes from './ArtistManager.module.scss';
import BannerDetailsDrawer from '../../ConversionFlowBanners/BannerDetailsDrawer';

const ArtistManager = ({ config: banner }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');
  const { language } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const imgSrc = banner.imageUrl[language] || banner.imageUrl.en;
  const { navigate, permissions } = usePageContext();
  const activeProfile = useActiveProfileData();
  const { isLoggedIn } = permissions;

  const handleOnClick = () => {
    if (!isLoggedIn || activeProfile?.profileType?.id !== ARTIST_TYPE_ID) {
      setIsOpen(true);
      return;
    }

    if (activeProfile?.profileType?.id === ARTIST_TYPE_ID) {
      const isPro = activeProfile?.subscriptionStatus === PROFILE_TYPES.PRO;

      if (isPro) {
        const linkProps = navigate.getLinkProps({
          entityType: ENTITY_TYPE.ARTIST,
          entity: activeProfile,
          onlyLinkProps: true,
        });
        navigate.to(linkProps);
      } else {
        setIsOpen(true);
      }
    }
  };

  return (
    <>
      <div
        className={classnames(classes.banner, {
          [classes.bannerReverse]: banner?.imagePosition === 'right',
          [classes[banner.className]]: !!banner.className,
        })}
        onClick={handleOnClick}
      >
        <div className={classes.banner__logoBlock}>
          <Image
            src="https://public.operabase.com/images/conversionFlow/logo_20240620T105126322Z.webp"
            alt="Operabase Home"
            title="Operabase Home"
            lazy={false}
            disableCloudinary
            disableNextImage
            className={classes.logo}
            width={110}
          />
          <Typography size={12} color={banner.className !== 'bannerFive' ? 'secondary' : 'white'}>
            {t(`${TP}.LP_SINCE`)}
          </Typography>
        </div>
        <div className={classes.banner__bgBlurredShade}></div>
        {banner?.disclaimer && (
          <Typography variant="p" className={classes.banner__disclaimer} size={12}>
            <Trans
              i18nKey={`${TP}.${banner.disclaimer}`}
              components={{ span: <span className={classes.starIcon} /> }}
              ns="NS_CONVERSION_FLOW"
            />
          </Typography>
        )}
        <div className={classes.banner__image}>
          <Image
            src={imgSrc}
            alt="Operabase Devices"
            title="Operabase Devices"
            lazy={false}
            width={banner.imageWidth || 500}
            className={classes.drawerBlock__left_devices}
            disableCloudinary
            disableNextImage
          />
        </div>
        <div className={classes.banner__textArea} style={{ width: banner?.textWidth }}>
          {banner.otherText?.text && (
            <Typography variant="p" style={banner.otherText.styles} className={classes.banner__textArea_otherText}>
              {t(`${TP}.${banner.otherText.text}`)}
            </Typography>
          )}
          <Typography variant="p" style={banner.title.styles} className={classes.banner__textArea_title}>
            <Trans
              i18nKey={`${TP}.${banner.title.text}`}
              components={{
                span: <span />,
              }}
              ns="NS_CONVERSION_FLOW"
            />
          </Typography>
          <Typography variant="p" style={banner.subTitle.styles} className={classes.banner__textArea_subTitle}>
            {t(`${TP}.${banner.subTitle.text}`)}
          </Typography>
          <LinkButton rightIcon={<SpriteIcon icon="chevron_right" />} styles={{ root: classes.button }} variant="text">
            {t(banner.buttonText)}
          </LinkButton>
        </div>
      </div>
      {isOpen && <BannerDetailsDrawer isOpen={isOpen} type="artist" onClose={() => setIsOpen(false)} showLoginText />}
    </>
  );
};

export default ArtistManager;
